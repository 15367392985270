<template>
  <v-container>
    <v-row justify="center" style="margin-top:30px">
      <v-col :cols="1"></v-col>
      <v-col :cols="5" sm="6"  class="dl-flex align-center">
        <span>体能筛选（优）</span>
        <v-select
          style="display:inline-block;max-width:50%"
          clearable
          multiple
          :menu-props="{ offsetY: true }"
          return-object
          :items="physicalScreeningOption"
          v-model="physicalScreeningSelection"
          dense
          outlined
      ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col :cols="4" class="dl-flex align-center">
        <span>民族</span>
        <v-select
            style="display:inline-block;margin-left: 5px;max-width:60%"
            clearable
            :menu-props="{ offsetY: true }"
            return-object
            :items="nationOption"
            v-model="nationSelection"
            dense
            outlined
        ></v-select>
      </v-col>
      <v-col :cols="1"></v-col>
    </v-row>
    <v-row  style="margin-top:0 !important;margin-bottom:12px">
      <v-col :cols="5"></v-col>
      <v-col :cols="1">
        <v-btn elevation="0" color="#6aadcf" style="color:white" @click="search(0,pageSize,false)">Search</v-btn>
      </v-col>
      <v-col :cols="1">
        <v-btn elevation="0" color="#6aadcf" style="color:white" @click="exampleSearch">Example</v-btn>
      </v-col>
      <v-col :cols="5"></v-col>
    </v-row>
    <div>
      <v-btn elevation="0" color="#6aadcf" style="color:white;margin:0 0 30px 90%" @click="JWBrowserSearch">基因组浏览器</v-btn>
    </div>
    <el-table :data="tableData.slice(0,pageSize)" :border=true @selection-change="handleSelectionChange" ref="multipleTable" row-key="id">
      <el-table-column
          type="selection"
          :reserve-selection="true"
          fixed="left"
          width="55">
      </el-table-column>
      <el-table-column
          v-for="(item,index) in tableHeader"
          :prop="item.value"
          :label="item.text"
          :fixed="false"
          :show-overflow-tooltip="true"
          min-width="130"
      >
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="viewInformation(scope.row)" type="text" size="small">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div v-if="pageShow" class="block" style="margin-top:15px;">
      <el-pagination align='center' @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="currentPage"
                     :page-sizes="[1,5,10,20]"
                     :page-size="pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </div>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'
import {statistics,getWorkflow} from '../JS/utils'

export default {
  name: "Sample",
  data: () => ({
    physicalScreeningSelection:[],//体能筛选选择的数据
    physicalScreeningOption:[//体能筛选下拉项数据
      {
        text: '3000米跑',
        value: '3000RunRating'
      },
      {
        text: '握力',
        value: 'gripRating'
      },
      {
        text: '俯卧撑',
        value: 'pushUpRating'
      },
      {
        text: '胸前跪抛球',
        value: 'kneelingRating'
      },
      {
        text: '垂直纵跳',
        value: 'verticalRating'
      },
      {
        text: '立定跳远',
        value: 'standingRating'
      },
      {
        text: '30米冲刺',
        value: '30RunningRating'
      },
      {
        text: '六边形跳',
        value: 'hexagonRating'
      },
      {
        text: 'T型跑',
        value: 'TRunningRating'
      },
      {
        text: '坐位体前屈',
        value: 'sittingRating'
      },
      {
        text: '体能总评',
        value: 'physicalFitnessRating'
      }
    ],
    nationSelection:[],//民族下拉框选择的数据
    nationOption:[//民族下拉框的选项数据
      {
        text:'民族1',
        value:'1'
      },
      {
        text:'民族2',
        value:'2'
      },
      {
        text:'民族3',
        value:'3'
      },
      {
        text:'民族4',
        value:'4'
      },
    ],
    searchTableData: [ //搜索结果表格的数据
      {
        sampleId: '163-2021-0084',
        gender: '男',
        age: '1200',
        nation: '汉',
        physicalFitnessScore: '100',
        overallFitnessRating: '优'
      }
    ],
    tableData:[],
    tableHeader:[
      {
        text:'样本ID',
        value:'id'
      },{
        text:'性别',
        value:'gender'
      },{
        text:'年龄',
        value:'age'
      },{
        text:'民族',
        value:'nation'
      },{
        text:'体能总分',
        value:'physicalFitnessScore'
      },{
        text:'3000米跑得分',
        value:'3000RunningResult'
      },{
        text:'握力得分',
        value:'gripScore'
      },{
        text:'俯卧撑得分',
        value:'pushUpScore'
      },{
        text:'胸前跪抛球得分',
        value:'kneelingScore'
      },{
        text:'垂直纵跳得分',
        value:'verticalScore'
      },{
        text:'立定跳远得分',
        value:'standingScore'
      },{
        text:'30米冲刺得分',
        value:'30RunningScore'
      },{
        text:'六边形跳得分',
        value:'hexagonScore'
      },{
        text:'T型跑得分',
        value:'TRunningScore'
      },{
        text:'坐位体前屈得分',
        value:'sittingScore'
      }
    ],
    currentPage:1,
    pageSize:10,
    total: 0, // 总条数
    pageShow: false,
    tableSelect: [],//表格多选框选中的数据
  }),
  props:['JWURL'],
  computed: {
    watchObj () {
      return {
        currentPage:this.currentPage,
        pageSize:this.pageSize
      }
    }
  },
  watch: {
    watchObj:{
      handler(newVal, oldVal){
        let oldSize = oldVal.pageSize,
            newSize = newVal.pageSize,
            oldPage = oldVal.currentPage,
            newPage = newVal.currentPage
        if(1){
          let skip = newSize * (newPage-1)
          this.search(skip,newSize,false)
        }
      },
      deep: true
    },
    tableData:{
      handler(newVal, oldVal){
        //如果表格数据变了，就判断当前表格数据每一条在不在tableSelect中，如果在，那就将该条数据设置为选中状态
      }
    }
  },
  async mounted(){
    await this.nationOptions()
    await this.search(0,this.pageSize,false)
  },
  methods:{
    ...mapActions("snackbar", ["openSuccessSnackbar","openFailureSnackbar","openInfoSnackbar", "openSnackbar"]),
    ...mapActions("overlay", ["openOverlay","closeOverlay"]),
    /**
     * 点击搜索按钮时触发
     */
    async search(skip,size,check=true){
      this.pageShow = true
      //search时要求体能筛选框和民族至少选择一个
      let physicalScreeningSelection = this.physicalScreeningSelection,
          physicalScreeningSelectionLength = physicalScreeningSelection.length,
          nationSelection = this.nationSelection,
          nationSelectionLength = nationSelection && Object.keys(nationSelection).length
      if(physicalScreeningSelectionLength || nationSelectionLength || !check){
        this.openOverlay()
        let filter = {
          "filterOption": {
            "filters": {
              "workflow": '62f0ac8864a77e00437abbbf',
            },
            "group": '61de80fa3f6359003ec98d55',
            "limit": size,
            "skip": skip
          }
        }
        physicalScreeningSelection.forEach(item=>{
          filter.filterOption.filters[`submission.${item.value}`] = {"$eq":"优"}
        })
        if(nationSelectionLength){
          filter.filterOption.filters[`submission.nation`] = {"$eq":nationSelection.value}
        }
        let res = await getWorkflow(filter),
            results = res.results
        this.tableData = []
        if(results.length){
          let len = results.length
          for (let i = 0; i < len; i++) {
            // let header = this.tableHeader,
            //     headerLen = header.length,
            //     temporaryObject = {}
            // for (let j = 0; j < headerLen; j++) {
            //   temporaryObject[header[j].value] = results[i]['submission'][header[j].value]
            // }
            this.tableData.push(results[i]['submission'])
          }
        }
        this.total=res.total
        this.closeOverlay()
      }else{
        this.openFailureSnackbar('请选择搜索条件!')
      }
    },
    /**
     * 点击表格每条数据的查看按钮，页面跳转
     * @param val {Object} 跳转页面携带的数据
     */
    viewInformation(val){
      this.$router.push({path:'/sampleChildrenPage',query:{value:JSON.stringify(val)}})
    },
    /**
     * 获取民族select框选项数据
     * @returns {Promise<void>}
     */
    async nationOptions(){
      if(!sessionStorage.getItem('physicalFitnessData')){
        //获取样本条数
        let physicalFitnessDataFilter = {
          "filterOption": {
            "filters": {
              "workflow": '62f0ac8864a77e00437abbbf',
            },
            "group": '61de80fa3f6359003ec98d55',
            "limit": 500
          }
        }
        let physicalFitnessData = await getWorkflow(physicalFitnessDataFilter)
        sessionStorage.setItem('physicalFitnessData',JSON.stringify(physicalFitnessData.results))
      }
      let allPhysicalFitnessData = JSON.parse(sessionStorage.getItem('physicalFitnessData')),
          nationData = statistics(allPhysicalFitnessData,item=>{return item.submission.nation})
      this.nationOption = []
      nationData.forEach(item => {this.nationOption.push({text:item.name,value:item.name})})
   },
    /**
     * example事件，自动填充选项并搜索结果
     * @returns {Promise<void>}
     */
    async exampleSearch(){
      let physicalScreeningOption = this.physicalScreeningOption,
          nationOption = this.nationOption
      if(physicalScreeningOption.length){
        this.physicalScreeningSelection = physicalScreeningOption.slice(0,1)
      }else{
        this.openFailureSnackbar('无选项数据!')
      }
      if(nationOption.length){
        this.nationSelection = nationOption[0]
      }else{
        this.openFailureSnackbar('无选项数据!')
      }
      await this.search(0,this.pageSize,false)
    },
    //每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleSelectionChange(val){
      //变化时将数据赋给tableSelect
      this.tableSelect = val
    },
    JWBrowserSearch(){
      //拿到当前选中值
      let selected = this.tableSelect,
          JWData = []
      if(selected.length){
        for (let i = 0; i < selected.length; i++) {
          JWData.push(selected[i]['id'] + '_methylation')
          JWData.push(selected[i]['id'] + '_RNA')
        }
        let url = `${this.JWURL}&tracks=DNA,${JWData.toString()}&highlight=`
        window.open(url)
      }else{
        this.openFailureSnackbar('请先选择数据！')
      }
    }
  }
}
</script>

<style scoped>

</style>